import React, { ComponentProps, ReactNode } from "react";

import { Box, HStack, Text, useColorModeValue } from "@chakra-ui/react";

import { times } from "remeda";

import { OrderSummary } from "..";
import { AppointmentService, FullCartFragment } from "../../graphql/generated/apolloHooks";
import { OnlyNonMobile } from "../../lib/media-query";
import { useFullCart, useServiceFee } from "../../models/cart";
import { useChain } from "../../models/chain";
import { productPriceAVista } from "../../models/product";

type Props = ComponentProps<typeof HStack> & {
  cart: FullCartFragment | undefined;
  fee: string | undefined;
  loading: boolean;
} & {
  children: ReactNode;
};

export const CartCheckout = ({ children, cart, fee, loading: _loading, ...props }: Props) => {
  const backgroundColor = useColorModeValue("gray.50", "whiteAlpha.50");
  return (
    <HStack w="full" align="flex-start" justify="space-between" spacing={6} {...props}>
      <>{children}</>
      <OnlyNonMobile>
        {cart && (
          <Box
            backgroundColor={backgroundColor}
            w={{ md: 300, lg: 400, xl: 500 }}
            padding={4}
            borderRadius="md"
          >
            <Text fontSize="xl" fontWeight="500" variant="roboto" as="h3" color="text.900">
              Resumo do pedido
            </Text>
            <OrderSummary
              minHeight={300}
              groupBy="products"
              service={cart.service!}
              fees={fee}
              numVisits={cart.numVisits || 1}
              total={cart.total}
              items={cart.items.flatMap(item =>
                times(item.quantity, () => ({
                  ...item,
                  price: productPriceAVista(item.product).toFixed(2),
                }))
              )}
            />
          </Box>
        )}
      </OnlyNonMobile>
    </HStack>
  );
};

export const CartCheckoutWithDataQuery = (
  props: ComponentProps<typeof HStack> & {
    children: ReactNode;
  }
) => {
  const { data, loading } = useFullCart({ useQueryOptions: { fetchPolicy: "cache-first" } });
  const { service } = data ?? {};
  const { fee, loading: loadingFee } = useServiceFee();
  const chain = useChain();
  return (
    <CartCheckout
      cart={data ?? undefined}
      fee={
        fee?.min.toString() ??
        (service === AppointmentService.ClinicVaccination
          ? chain?.clinicServiceFee.min
          : chain?.homeServiceFee.min)
      }
      loading={loading || loadingFee}
      {...props}
    />
  );
};
